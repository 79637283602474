import { where } from 'firebase/firestore'
import Controller from '../controllers/base.controller'
import MeasurementEntity from '../entities/measurement.entity'

const baseCol = 'measurements'

/**
 * Gets all measurements from the given collection, including from its
 * children.
 *
 * @param {string} collection the collection to get the measurements from.
 * @returns {Promise<{ measurements: MeasurementEntity[], paths: Record<string, string>}>} a list of measurements.
 */
export async function getAllMeasurements(collection) {
  const controller = new Controller()

  const parents = await controller.getAll(
    [collection],
    [where('qtyMeasurements', '>', 0)],
  )

  let possibleSubCollections = []

  const parts = collection.split('/')
  switch (parts[parts.length - 1]) {
    case 'software-partners':
      possibleSubCollections = ['laboratories', 'optics', 'sellers']
      break
    case 'laboratories':
      possibleSubCollections = ['optics', 'sellers']
      break
    case 'optics':
      possibleSubCollections = ['sellers']
      break
    default:
      possibleSubCollections = []
  }

  const parentMeasurements = []

  /**
   * @type {[key: string]: string}
   */
  let paths = {}

  for (let doc of parents.docs) {
    const measurements = await controller.getAll([collection, doc.id, baseCol])
    parentMeasurements.push(
      ...measurements.docs.map((m) => {
        paths[m.id] = `${collection}/${doc.id}/${baseCol}/${m.id}`

        return MeasurementEntity.fromFirestore({
          ...m.data(),
          id: m.id,
        })
      }),
    )

    for (let col of possibleSubCollections) {
      const subMeasurements = await getAllMeasurements(
        `${collection}/${doc.id}/${col}`,
      )
      parentMeasurements.push(...subMeasurements.measurements)
      paths = { ...paths, ...subMeasurements.paths }
    }
  }

  return { measurements: [...parentMeasurements], paths }
}
