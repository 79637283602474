import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import SellerController from '@/controllers/seller.controller'
import SellerEntity from '@/entities/seller.entity'

const sellerController = new SellerController()

export default {
  namespaced: true,
  state: {
    sellers: [],
    loading: false,
    loadingSellers: false,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the seller state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingSellers` property.
     *
     * @param {Object} state the seller state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_SELLERS(state, data) {
      state.loadingSellers = data
    },

    /**
     * Sets the `sellers` property.
     *
     * @param {Object} state the seller state.
     * @param {SellerEntity[]} data an array of sellers.
     */
    SET_SELLERS(state, data) {
      state.sellers = data
    },
  },
  actions: {
    /**
     * Sets an seller into the database according to the given id.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.id the seller id.
     * @param {SellerEntity} payload.data the seller data.
     */
    async setSeller({ commit }, payload) {
      commit('SET_LOADING', true)

      const { id, data, parent } = payload

      const now = new Date().toISOString()

      data.updatedAt = now

      try {
        if (!id) {
          data.createdAt = now
          return await sellerController.create(new SellerEntity(data), parent)
        }

        return await sellerController.update(new SellerEntity(data), id, parent)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads sellers according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the fetch path.
     */
    async fetchSellers({ commit }, payload) {
      commit('SET_LOADING_SELLERS', true)

      try {
        const path = payload ? `${payload}/sellers` : 'sellers'

        sellerController.listenMultiple(path, (sellers) => {
          commit('SET_SELLERS', sellers)
        })
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING_SELLERS', false)
      }
    },

    /**
     * Get a seller from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the seller id.
     * @returns a seller entity.
     */
    async fetchOneSeller({ state, commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const seller = await sellerController.getOne(payload)

        if (!seller) {
          throw new Error('seller-not-found')
        }

        return seller
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Spft deletes a seller from the database.
     *
     * @param {Store} store the vuex store.
     * @param {Object} payload the action payload.
     * @param {string} payload.path the document path.
     * @param {string} payload.userId the user id.
     */
    async deleteSeller({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await sellerController.softDelete(payload.path, payload.userId)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Restores a seller from the soft delete.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the document path.
     */
    async restoreSeller({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        await sellerController.restore(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
