<template>
  <b-card text-variant="center" class="card card-congratulations">
    <!-- images -->

    <!--/ images -->
    <b-avatar
      v-if="logoUrl"
      variant="primary"
      size="70"
      class="shadow mb-2"
      :src="logoUrl"
      :text="
        userAdminDocData.nameFantasy[0] || userAdminDocData.nameRegistered[0]
      "
    />
    <b-avatar v-else variant="primary" size="70" class="shadow mb-2">
      <feather-icon size="28" icon="AwardIcon" />
    </b-avatar>

    <h1 v-if="user" class="mb-1 mt-50 text-white">
      {{ $t('home.hello') }}
      {{ `${user.name} ${user.lastName}` }}
    </h1>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText } from 'bootstrap-vue'
import { createStorageUrl } from '@/utils/images'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  computed: {
    userAdminDocData() {
      return this.$store.state.auth.userAdminDoc
    },
    user() {
      return this.$store.state.auth.user
    },
    logoUrl() {
      if (this.userAdminDocData)
        return createStorageUrl(this.userAdminDocData.logo)
      return ''
    },
  },
}
</script>
