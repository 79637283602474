/**
 * Gets a local URL from the given image.
 *
 * @param {File} file the image file.
 * @returns {Promise<String>} the image local URL.
 * @throws 'file-not-provided' if no file is provided
 */
export async function readUrl(file) {
  if (!file) {
    throw new Error('file-not-provided')
  }

  return new Promise((res) => {
    const reader = new FileReader()
    reader.onload = async (e) => {
      res(e.target.result)
    }
    reader.readAsDataURL(file)
  })
}

/**
 * Creates an URL from the given path of an image stored into the
 * Firebase storage.
 *
 * @param {string} path the image path into the Firebase storage.
 * @returns the image URL.
 */
export function createStorageUrl(path) {
  return `https://firebasestorage.googleapis.com/v0/b/${
    process.env.VUE_APP_STORAGE_BUCKET
  }/o/${encodeURIComponent(path)}?alt=media`
}

/**
 * Gets the file extension.
 *
 * @param {File} file the file to be checked.
 * @returns the file extension.
 */
export function getExtension(file) {
  if (!file) {
    return ''
  }

  const regex = /(?:[^\.](?!\.))+$/gim
  const matches = file.name.match(regex)
  return matches ? matches[0] : ''
}
