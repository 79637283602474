<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div v-if="isSoftwareAdmin || isSuperAdmin">
          <b-row align-v="end" class="pb-2">
            <!-- Software Partners Filter -->
            <b-col v-if="isSuperAdmin" cols="12" lg="3" md="3">
              <div>
                <small>{{ $t('admin.labs.opticsList.softwarePartner') }}</small>
                <v-select
                  v-model="selectedSoftwarePartner"
                  :options="filterSoftwarePartners"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>

            <!-- Labs Filter -->
            <b-col
              cols="12"
              :lg="isSuperAdmin ? 3 : 4"
              :md="isSuperAdmin ? 3 : 4"
            >
              <div>
                <small>{{ $t('admin.labs.opticsList.lab') }}</small>
                <v-select
                  v-model="selectedLab"
                  :options="filterLabs"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>

            <!-- Optics Filter -->
            <b-col
              cols="12"
              :lg="isSuperAdmin ? 3 : 4"
              :md="isSuperAdmin ? 3 : 4"
            >
              <div>
                <small>{{ $t('admin.labs.sellersList.optic') }}</small>
                <v-select
                  v-model="selectedOptic"
                  :options="filterOptics"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>

            <!-- Sellers Filter -->
            <b-col
              cols="12"
              :lg="isSuperAdmin ? 3 : 4"
              :md="isSuperAdmin ? 3 : 4"
            >
              <div>
                <small>{{ $t('admin.measurements.list.seller') }}</small>
                <v-select
                  :disabled="!isOpticChosen"
                  v-model="selectedSeller"
                  :options="filterSellers"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>

            <b-col class="mt-2">
              <b-button block variant="danger" @click="clearFilter()">
                {{ $t('admin.labs.opticsList.cleanFilter') }}
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row align-h="between">
            <!-- Per Page -->
            <b-col
              cols="12"
              lg="3"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('admin.measurements.list.show') }}</label>

              <v-select
                v-model="perPage"
                class="per-page-selector d-inline-block mx-50"
                :options="perPageOptions"
                :clearable="false"
              />

              <label>{{ $t('admin.measurements.list.entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" lg="6" md="6">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="search"
                  class="d-inline-block"
                  :placeholder="$t('admin.measurements.list.searchBy')"
                />
                <b-button
                  variant="success"
                  class="ml-1"
                  :disabled="$store.getters['auth/inactive']"
                  @click="openMeasurementCreateModal()"
                >
                  <span class="text-nowrap">{{
                    $t('admin.measurements.list.create')
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="isLabAdmin">
          <b-row align-v="end" class="pb-2">
            <!-- Optics Filter -->
            <b-col cols="12" lg="3" md="3" v-if="isLabAdmin">
              <div>
                <small>{{ $t('admin.measurements.list.optic') }}</small>
                <v-select
                  v-model="selectedOptic"
                  :options="optics"
                  :clearable="false"
                  label="nameRegistered"
                />
              </div>
            </b-col>

            <!-- Sellers Filter -->
            <b-col cols="12" lg="3" md="3" v-if="isOpticAdmin || isLabAdmin">
              <div>
                <small>{{ $t('admin.measurements.list.seller') }}</small>
                <v-select
                  :disabled="isLabAdmin && !isOpticChosen"
                  v-model="selectedSeller"
                  :options="sellers"
                  :clearable="false"
                  label="nameRegistered"
                />
              </div>
            </b-col>

            <b-col>
              <b-button block variant="danger" @click="clearFilter()">
                {{ $t('admin.labs.opticsList.cleanFilter') }}
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col cols="12" lg="4" md="4">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="search"
                  class="d-inline-block"
                  :placeholder="$t('admin.measurements.list.searchBy')"
                />
                <b-button
                  variant="success"
                  class="ml-1"
                  :disabled="$store.getters['auth/inactive']"
                  @click="openMeasurementCreateModal()"
                >
                  <span class="text-nowrap">{{
                    $t('admin.measurements.list.create')
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            lg="3"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.measurements.list.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.measurements.list.entries') }}</label>
          </b-col>
        </div>
        <b-row align-v="end" align-h="between" v-else>
          <!-- Per Page -->
          <b-col
            cols="12"
            lg="3"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.measurements.list.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.measurements.list.entries') }}</label>
          </b-col>

          <!-- Sellers Filter -->
          <b-col cols="12" lg="3" md="3" v-if="isOpticAdmin">
            <div>
              <small>{{ $t('admin.measurements.list.seller') }}</small>
              <v-select
                v-model="selectedSeller"
                :options="sellers"
                :clearable="false"
                label="nameRegistered"
              />
            </div>
          </b-col>

          <b-col>
            <b-button block variant="danger" @click="clearFilter()">
              {{ $t('admin.labs.opticsList.cleanFilter') }}
              <feather-icon icon="XIcon" />
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" lg="4" md="4">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="search"
                class="d-inline-block"
                :placeholder="$t('admin.measurements.list.searchBy')"
              />
              <b-button
                variant="success"
                class="ml-1"
                :disabled="$store.getters['auth/inactive']"
                @click="openMeasurementCreateModal()"
              >
                <span class="text-nowrap">{{
                  $t('admin.measurements.list.create')
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        id="my-table"
        ref="refMeasurementsListTable"
        responsive
        show-empty
        primary-key="id"
        :empty-text="$t('admin.measurements.list.noEntries')"
        class="position-relative"
        :filter="search"
        :items="filteredItems"
        :fields="tableColumns"
        :sort-by="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="$store.state.measurements.loading"
        @row-clicked="viewMeasurements"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.measurements.list.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(consumer)="data">
          <span>{{ data.item.consumer || '-' }}</span>
        </template>

        <template #cell(serviceOrder)="data">
          <b-badge
            :variant="data.item.serviceOrder === 'QR' ? 'warning' : 'secondary'"
          >
            <span>{{ data.item.serviceOrder || '-' }}</span>
          </b-badge>
        </template>

        <template #cell(updatedAt)="data">
          <span>{{ beautyParserDateToString(data.item.updatedAt) }}</span>
        </template>

        <template #cell(time)="data">
          <span>{{ beautyParserHourToString(data.item.updatedAt) }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge
            style="margin: 3px"
            :variant="getStatusVariant(data.item.results)"
          >
            {{ getStatusText(data.item.results) }}
          </b-badge>
        </template>

        <template #cell(type)="data">
          <b-row align-h="center">
            <div v-if="data.item.measurement_progress">
              <div
                v-for="(type, i) in data.item.measurement_progress.measurements
                  .complete"
                :key="i"
              >
                <b-badge style="margin: 3px" :variant="getTypeVariant(type)">
                  {{ getTypeText(type) }}
                </b-badge>
              </div>
            </div>
            <div v-else>
              {{ '-' }}
            </div>
          </b-row>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end" v-if="!isSuperAdmin">
            <b-button
              size="sm"
              variant="outline-primary"
              :id="`view-${data.item.id}`"
              :disabled="$store.getters['auth/inactive']"
              @click="viewMeasurements(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`view-${data.item.id}`"
            >
              <b>{{ $t('admin.measurements.list.view') }}</b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              variant="outline-info"
              :id="`edit-${data.item.id}`"
              :disabled="$store.getters['auth/inactive']"
              @click="editMeasurement(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`edit-${data.item.id}`"
            >
              <b>{{ $t('admin.measurements.list.edit') }}</b>
            </b-tooltip>

            <b-button
              v-if="!data.item.results"
              size="sm"
              class="ml-1"
              variant="outline-danger"
              :id="`delete-${data.item.id}`"
              :disabled="$store.getters['auth/inactive']"
              @click="
                () => {
                  selectedItem = data.item
                  deleteModal = true
                }
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>

            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{ $t('admin.measurements.list.delete') }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('admin.measurements.list.totalOf') }}
              {{ totalItems }}
              {{ $t('admin.measurements.list.entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :per-page="perPage"
              aria-controls="my-table"
              :total-rows="totalItems"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('admin.measurements.list.deleteDialog.message')"
      class="red--text"
      :title="$t('admin.measurements.list.deleteDialog.title')"
      @action="
        deleteMeasurement(selectedItem.id)
        selectedItem = {}
        deleteModal = false
      "
      @close="deleteModal = false"
    />

    <AlertDialog
      :message="$t('admin.measurements.list.CreateDialog.message')"
      :title="$t('admin.measurements.list.CreateDialog.title')"
      :id="`create-measurement-block`"
      :show="cannotCreateMeasurementModal"
      :warnOnly="true"
      @close="cannotCreateMeasurementModal = false"
    />

    <MeasurementCreateDialog
      id="measurement-create-dialog"
      :show="createModal"
      @close="createModal = false"
      @action="
        createMeasurement($event)
        createModal = false
      "
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import AlertDialog from '@/components/AlertDialog.vue'
import MeasurementCreateDialog from './components/MeasurementCreateDialog.vue'

import {
  beautyParserDateToString,
  beautyParserHourToString,
} from '../../../utils/dates'

import { onUnmounted, ref } from 'vue-demi'
import store from '@/store'

import RoleEnum from '@/utils/roles'
import { checkIfEntityHasMeasurements } from '@/utils/plans'

import measurementStoreModule from './measurementStoreModule'
import useMeasurementsList from './useMeasurementsList'

import Controller from '@/controllers/base.controller'

import useSellersList from '@/views/sections/sellers/useSellersList'
import useOpticsList from '@/views/sections/optics/useOpticsList'
import useLabsList from '@/views/sections/labs/useLabsList'
import useSoftwarePartnersList from '@/views/sections/software-partners/useSoftwarePartnersList'

import sellerStoreModule from '@/views/sections/sellers/sellerStoreModule'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import spStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'

const measurerToolBaseUrl = process.env.VUE_APP_MEASURER_TOOL_URL

export default {
  name: 'MeasurementsList',

  props: ['preDefinedPerPage'],

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
    MeasurementCreateDialog,
  },

  data() {
    return {
      deleteModal: false,
      createModal: false,
      selectedItem: {},
      cannotCreateMeasurementModal: false,
      beautyParserDateToString,
      beautyParserHourToString,
    }
  },
  watch: {
    selectedOptic() {
      this.selectedSeller = null

      this.refetchMeasurements()
      this.refetchSellers()
    },
    selectedSeller() {
      this.refetchMeasurements()
    },
    selectedLab() {
      this.selectedOptic = null
      this.selectedSeller = null

      this.refetchMeasurements()
      this.refetchOptics()
    },
    selectedSoftwarePartner() {
      this.selectedOptic = null
      this.selectedLab = null
      this.selectedSeller = null

      this.refetchMeasurements()
      this.refetchLabs()
      this.refetchOptics()
    },
  },

  created() {
    if (this.preDefinedPerPage) this.perPage = this.preDefinedPerPage
  },

  computed: {
    isSoftwareAdmin() {
      return this.user.role === RoleEnum.SOFTWARE_ADMIN
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN
    },
    isOpticChosen() {
      if (this.selectedOptic && !this.selectedOptic.me) return true
      return false
    },
    isOpticAdmin() {
      if (this.user) {
        return this.user.role === RoleEnum.OPTIC_ADMIN
      }
    },
    isSeller() {
      return this.user.role === RoleEnum.SELLER
    },
    isLabAdmin() {
      if (this.user) {
        return this.user.role === RoleEnum.LABORATORY_ADMIN
      }
    },
    user() {
      return this.$store.state.auth.user
    },
    userAdminDoc() {
      return this.$store.state.auth.userAdminDoc
    },
    filteredItems() {
      if (this.measurements !== null && this.measurements !== undefined) {
        return this.measurements.filter((el) =>
          el.id.toLowerCase().includes(this.search.toLowerCase()),
        )
      }
      return []
    },
    totalItems() {
      return this.filteredItems.length || 0
    },
    filterSoftwarePartners() {
      return this.softwarePartners.filter((el) => el.status == 'active')
    },
    filterLabs() {
      return this.labs.filter((el) => el.status == 'active')
    },
    filterOptics() {
      return this.optics.filter((el) => el.status == 'active')
    },
    filterSellers() {
      return this.sellers.filter((el) => el.status == 'active')
    },
  },

  methods: {
    checkIfEntityHasMeasurements,
    viewMeasurements(item) {
      let parent = `${this.user.adminDocRef || this.user.sellerDocRef}`
      if (this.isLabAdmin) {
        if (this.selectedOptic && !this.selectedOptic.me) {
          parent += `/optics/${this.selectedOptic.id}`
        }
        if (this.selectedSeller && !this.selectedSeller.me) {
          parent += `/sellers/${this.selectedSeller.id}`
        }
      } else if (this.isOpticAdmin) {
        if (this.selectedSeller && !this.selectedSeller.me) {
          parent += `/sellers/${this.selectedSeller.id}`
        }
      }

      const paths = this.$store.state.measurements.measurementPaths

      if (!parent && paths && paths[item.id]) {
        parent = paths[item.id].split('/measurements')[0]
      }

      this.$router.push(`/admin/measurements/${item.id}?p=${parent}`)
    },
    clearFilter() {
      this.search = ''
      this.selectedLab = null
      this.selectedOptic = null
      this.selectedSeller = null
      this.selectedSoftwarePartner = null

      this.refetchMeasurements()
    },
    async refetchMeasurements() {
      const adminDoc = this.$store.state.auth.userAdminDoc
      let path = ''

      if (this.isSuperAdmin) {
        if (this.selectedSoftwarePartner) {
          path = `software-partners/${this.selectedSoftwarePartner.id}`
        }

        if (this.selectedLab) {
          if (path) {
            path += '/'
          }

          path += `laboratories/${this.selectedLab.id}`
        }

        if (this.selectedOptic) {
          if (path) {
            path += '/'
          }

          path += `optics/${this.selectedOptic.id}`
        }

        if (this.selectedSeller) {
          if (path) {
            path += '/'
          }

          path += `sellers/${this.selectedSeller.id}`
        }
      } else if (this.isSoftwareAdmin) {
        if (this.selectedLab && this.selectedOptic && this.selectedSeller) {
          path = `software-partners/${adminDoc.id}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}/sellers/${this.selectedSeller.id}`
        } else if (this.selectedLab && this.selectedOptic) {
          path = `software-partners/${adminDoc.id}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
        } else if (this.selectedLab) {
          path = `software-partners/${adminDoc.id}/laboratories/${this.selectedLab.id}`
        } else {
          path = `software-partners/${adminDoc.id}`
        }
      } else if (this.isLabAdmin) {
        if (this.selectedOptic && this.selectedSeller) {
          path = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}/sellers/${this.selectedSeller.id}`
        } else if (this.selectedOptic) {
          path = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}`
        } else {
          path = `${this.user.adminDocRef}`
        }
      } else if (this.isOpticAdmin) {
        if (this.selectedSeller) {
          path = `${this.user.adminDocRef}/sellers/${this.selectedSeller.id}`
        } else {
          path = `${this.user.adminDocRef}`
        }
      } else if (this.isSeller) {
        path = `${this.user.sellerDocRef}`
      }

      await this.$store.dispatch('measurements/fetchMeasurements', path)
    },
    async refetchOptics() {
      const path = this.getOpticsParent()
      this.$store.dispatch('optic/fetchOptics', path)
    },
    async refetchLabs() {
      let path = ''

      if (this.isSuperAdmin) {
        if (this.selectedSoftwarePartner) {
          path = `software-partners/${this.selectedSoftwarePartner.id}`
        }
      } else if (this.isSoftwareAdmin) {
        path = `${this.user.adminDocRef}`
      }

      this.$store.dispatch('laboratory/fetchLabs', path)
    },
    getOpticsParent() {
      let path = ''
      if (this.isSoftwareAdmin) {
        if (this.selectedLab) {
          path = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}`
        }
      } else if (this.isSuperAdmin) {
        if (this.selectedSoftwarePartner && this.selectedLab) {
          path = `software-partners/${this.selectedSoftwarePartner.id}/laboratories/${this.selectedLab.id}`
        } else if (this.selectedSoftwarePartner) {
          path = `software-partners/${this.selectedSoftwarePartner.id}`
        } else if (this.selectedLab) {
          path = `laboratories/${this.selectedLab.id}`
        }
      }

      return path
    },
    refetchSellers() {
      let path = ''

      if (this.isSuperAdmin) {
        if (this.selectedSoftwarePartner) {
          path = `software-partners/${this.selectedSoftwarePartner.id}`
        }

        if (this.selectedLab) {
          if (path) {
            path += '/'
          }

          path += `laboratories/${this.selectedLab.id}`
        }

        if (this.selectedOptic) {
          if (path) {
            path += '/'
          }

          path += `optics/${this.selectedOptic.id}`
        }
      } else if (this.isSoftwareAdmin) {
        if (this.selectedLab && this.selectedOptic) {
          path = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
        }
      } else if (this.isLabAdmin) {
        path = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}`
      } else if (this.isOpticAdmin) {
        path = `${this.user.adminDocRef}`
      }

      store.dispatch('seller/fetchSellers', path)
    },
    getTypeText(type) {
      if (type === 'glasses front dnp') return 'DNP'
      else if (type === 'glasses front complete') return 'Progressiva'
      else if (type === 'glasses side complete') return 'Pantoscópica'
      else if (type === 'card card complete') return 'DNP - Cartão'
      else return
    },
    getTypeVariant(type) {
      if (type === 'glasses front dnp') return 'success'
      else if (type === 'glasses front complete') return 'info'
      else if (type === 'glasses side complete') return 'primary'
      else if (type === 'card card complete') return 'secondary'
      else return 'light'
    },
    getStatusVariant(results) {
      if (results) return 'success'
      else return 'warning'
    },
    getStatusText(results) {
      if (results) return 'Completo'
      else return 'Incompleto'
    },
    openMeasurementCreateModal() {
      this.createModal = true
    },
    async createMeasurement({ consumer, serviceOrder } = {}) {
      let canCreate = false

      if (this.user.role !== RoleEnum.SUPER_ADMIN) {
        canCreate = await this.checkIfEntityHasMeasurements(
          this.$store.state.auth.userAdminDoc,
          this.user,
        )
      } else {
        canCreate = true
      }

      if (!canCreate) {
        this.cannotCreateMeasurementModal = true
        return
      }

      const parent = this.user.adminDocRef || this.user.sellerDocRef || ''
      let url = `${measurerToolBaseUrl}/#?p=${parent}&consumer=${consumer}&serviceOrder=${serviceOrder}`

      if (this.user.role === RoleEnum.SUPER_ADMIN) {
        url += `&isSa=true`
      }

      let logo
      let isPlanParent = this.isPlanParent()
      const adminDoc = this.$store.state.auth.userAdminDoc

      if (isPlanParent) {
        if (adminDoc) logo = adminDoc.logo
      } else {
        const parentBaseUrl = parent.split('/')
        const length = parentBaseUrl.length
        parentBaseUrl.splice(length - 2, 2)

        const parentDocument = await new Controller().getById(parentBaseUrl)
        logo = parentDocument.data().logo

        if (!logo || !logo.length) {
          logo = adminDoc.logo
        }
      }

      if (logo) {
        url += `&logo=${logo}`
      }

      url += `&lang=${this.$i18n.locale}`

      window.open(url)
    },
    isPlanParent() {
      if (this.user.role === RoleEnum.SELLER) {
        return false
      } else {
        const planParent =
          this.user.adminDocRef.split('/').length > 2 ? false : true

        if (!planParent) {
          return false
        }
        return true
      }
    },
    async editMeasurement(id) {
      let parent = this.user.adminDocRef || this.user.sellerDocRef || ''

      if (this.isLabAdmin) {
        if (this.selectedOptic && !this.selectedOptic.me) {
          parent += `/optics/${this.selectedOptic.id}`
        }
        if (this.selectedSeller && !this.selectedSeller.me) {
          parent += `/sellers/${this.selectedSeller.id}`
        }
      } else if (this.isOpticAdmin) {
        if (this.selectedSeller && !this.selectedSeller.me) {
          parent += `/sellers/${this.selectedSeller.id}`
        }
      }

      let url = `${measurerToolBaseUrl}/#/tool/${id}?p=${parent}&lang=${this.$i18n.locale}`

      if (this.user.role === RoleEnum.SUPER_ADMIN) {
        url += `&isSa=true`
      }

      let logo
      let isPlanParent = this.isPlanParent()
      const adminDoc = this.$store.state.auth.userAdminDoc

      if (isPlanParent) {
        if (adminDoc) logo = adminDoc.logo
      } else {
        const parentBaseUrl = parent.split('/')
        const length = parentBaseUrl.length
        parentBaseUrl.splice(length - 2, 2)
        const parentDocument = await new Controller().getById(parentBaseUrl)
        logo = parentDocument.data().logo
        if (logo === null || logo === undefined || logo.length === 0) {
          logo = adminDoc.logo
        }
      }

      if (logo) {
        url += `&logo=${logo}`
      }

      window.open(url)
    },
  },

  setup() {
    const MEASUREMENT_APP_STORE_MODULE_NAME = 'measurements'
    const SELLER_APP_STORE_MODULE_NAME = 'seller'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const SP_APP_STORE_MODULE_NAME = 'software-partner'

    if (!store.hasModule(MEASUREMENT_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MEASUREMENT_APP_STORE_MODULE_NAME,
        measurementStoreModule,
      )
    }
    if (!store.hasModule(SELLER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SELLER_APP_STORE_MODULE_NAME, sellerStoreModule)
    }
    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }
    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }
    if (!store.hasModule(SP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SP_APP_STORE_MODULE_NAME, spStoreModule)
    }

    onUnmounted(() => {
      store.hasModule(MEASUREMENT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(MEASUREMENT_APP_STORE_MODULE_NAME)

      store.hasModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SELLER_APP_STORE_MODULE_NAME)

      store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME)

      store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME)

      store.hasModule(SP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SP_APP_STORE_MODULE_NAME)
    })

    const role = store.state.auth.user.role
    const adminDocRef = store.state.auth.user.adminDocRef

    const {
      currentPage,
      isSortDirDesc,
      refMeasurementsListTable,
      search,
      sortBy,
      tableColumns,
      measurements,
      deleteMeasurement,
      perPage,
      perPageOptions,
    } = useMeasurementsList()

    const { sellers } = useSellersList()
    const { optics } = useOpticsList()
    const { labs } = useLabsList()
    const { softwarePartners, fetchSoftwarePartners } =
      useSoftwarePartnersList()

    const selectedLab = ref(null)

    const selectedOptic = ref(null)
    const selectedSeller = ref(null)

    const selectedSoftwarePartner = ref(null)

    if (role === RoleEnum.OPTIC_ADMIN) {
      store.dispatch('seller/fetchSellers', adminDocRef)
      store.dispatch('measurements/fetchMeasurements', adminDocRef)
    } else if (role === RoleEnum.LABORATORY_ADMIN) {
      store.dispatch('optic/fetchOptics', adminDocRef)
      store.dispatch('measurements/fetchMeasurements', adminDocRef)
    } else if (role === RoleEnum.SUPER_ADMIN) {
      fetchSoftwarePartners()
      store.dispatch('laboratory/fetchLabs')
      store.dispatch('optic/fetchOptics')
      store.dispatch('seller/fetchSellers')

      store.dispatch('measurements/fetchMeasurements')
    } else if (role === RoleEnum.SOFTWARE_ADMIN) {
      store.dispatch('laboratory/fetchLabs', adminDocRef)
      store.dispatch('measurements/fetchMeasurements', adminDocRef)
    } else {
      store.dispatch(
        'measurements/fetchMeasurements',
        adminDocRef || store.state.auth.user.sellerDocRef,
      )
    }

    return {
      selectedOptic,
      selectedSeller,
      selectedLab,
      selectedSoftwarePartner,

      sellers,
      optics,
      labs,
      softwarePartners,

      currentPage,
      isSortDirDesc,
      refMeasurementsListTable,
      search,
      sortBy,
      tableColumns,
      measurements,
      deleteMeasurement,
      perPage,
      perPageOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#my-table tbody tr {
  cursor: pointer;

  &:hover {
    background: #aaa3;
  }
}
</style>
