import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import MeasurementEntity from '../../../entities/measurement.entity'
import RoleEnum from '../../../utils/roles'

export default function useMeasureMentsList() {
  /**
   * Property that defines a reference to the Measurements table.
   */
  const refMeasurementsListTable = ref(null)

  /**
   * Property that defines the searching string from the Measurement list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('updatedAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Property that stores the fetched Measurements.
   *
   * @type {import('@vue/composition-api').ComputedRef<MeasurementEntity[]>}
   */
  const measurements = computed({
    get: () => store.state.measurements.measurements,
  })

  /**
   * Property that defines the measurements list headers.
   */
  const tableColumns = computed(() => {
    return [
      // { key: 'id', label: 'id', sortable: false },
      { key: 'numId', label: 'Nº', sortable: true },
      {
        key: 'consumer',
        label: i18n.t('admin.measurementForm.consumer'),
        sortable: true,
      },
      {
        key: 'serviceOrder',
        label: i18n.t('admin.measurementForm.serviceOrder'),
        sortable: true,
      },
      {
        key: 'updatedAt',
        label: i18n.t('admin.measurements.list.tableHeaders.date'),
        sortable: true,
      },
      {
        key: 'time',
        label: i18n.t('admin.measurements.list.tableHeaders.time'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.measurements.list.tableHeaders.stats'),
        sortable: false,
      },
      {
        key: 'type',
        label: i18n.t('admin.measurements.list.tableHeaders.type'),
        sortable: false,
      },
      // {
      //   key: 'frontal_picture',
      //   label: i18n.t('admin.measurements.list.tableHeaders.frontalPicture'),
      //   sortable: false,
      // },
      // {
      //   key: 'side_picture',
      //   label: i18n.t('admin.measurements.list.tableHeaders.sidePicture'),
      //   sortable: false,
      // },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes an measurement from the database.
   *
   * @param {string} id the measurement id.
   */
  const deleteMeasurement = async (id) => {
    try {
      const superAdmin = store.state.auth.user.role === RoleEnum.SUPER_ADMIN
      const docRef =
        store.state.auth.user.adminDocRef || store.state.auth.user.sellerDocRef

      let path = ''

      if (superAdmin) {
        path = id
      } else {
        path = `${docRef}/measurements/${id}`
      }

      await store.dispatch('measurements/deleteMeasurement', path)

      if (superAdmin) {
        store.dispatch('measurements/fetchAllMeasurements')
      } else {
        store.dispatch('measurements/fetchMeasurements', docRef)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refMeasurementsListTable,
    search,
    sortBy,
    tableColumns,
    deleteMeasurement,
    measurements,
    perPage,
    perPageOptions,
  }
}
