<template>
  <b-modal
    v-model="modal"
    hide-header
    ok-variant="primary"
    cancel-variant="outline-secondary"
    :ok-title="$t('alertDialog.ok')"
    :cancel-title="$t('alertDialog.cancel')"
    :hide-header-close="true"
    :centered="true"
    :ok-disabled="!this.consumerName || !this.serviceOrder"
    @cancel="close"
    @ok="callback"
  >
    <div class="container">
      <div class="row mb-2 mt-2">
        <h3>
          <b>{{ $t('admin.labs.opticSignWarn.sectionTitle') }}</b>
        </h3>
      </div>

      <validation-observer ref="refFormObserver">
        <b-form class="mt-1">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="$t('admin.measurementForm.consumer')"
                rules="required"
              >
                <b-form-group
                  :label="$t('admin.measurementForm.consumer')"
                  label-for="consumerName"
                >
                  <b-form-input
                    v-model="consumerName"
                    class="form-control"
                    :placeholder="$t('admin.measurementForm.consumer')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                :name="$t('admin.measurementForm.serviceOrder')"
                rules="required"
              >
                <b-form-group
                  :label="$t('admin.measurementForm.serviceOrder')"
                  label-for="serviceOrder"
                >
                  <b-form-input
                    v-model="serviceOrder"
                    class="form-control"
                    :placeholder="$t('admin.measurementForm.serviceOrder')"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      modal: false,
      consumerName: '',
      serviceOrder: '',
    }
  },
  watch: {
    show() {
      this.modal = this.show

      if (this.modal) {
        this.consumerName = ''
        this.serviceOrder = ''
      }
    },
    modal(value) {
      if (!value) {
        this.$emit('close')
      }
    },
  },
  methods: {
    close() {
      this.modal = false
      this.$emit('close')
    },
    callback() {
      if (!this.consumerName || !this.serviceOrder) {
        return
      }

      this.modal = false
      this.$emit('action', {
        consumer: this.consumerName,
        serviceOrder: this.serviceOrder,
      })
    },
  },

  setup() {
    const { refFormObserver, getValidationState, resetForm } = formValidation({
      consumer: '',
      serviceOrder: '',
    })

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
