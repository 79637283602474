import { QueryConstraint } from 'firebase/firestore'
import { Store } from 'vuex'

import MeasurementController from '../../../controllers/measurement.controller'
import MeasurementEntity from '../../../entities/measurement.entity'
import { getAllMeasurements } from '../../../utils/measurements'

const measurementController = new MeasurementController()

export default {
  namespaced: true,
  state: {
    measurements: [],
    measurementPaths: null,
    loading: false,
    loadingMeasurements: false,
    currentMeasurement: null,
  },
  getters: {},
  mutations: {
    /**
     * Sets the `loading` property.
     *
     * @param {Object} state the measurement state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING(state, data) {
      state.loading = data
    },

    /**
     * Sets the `loadingMeasurements` property.
     *
     * @param {Object} state the measurement state.
     * @param {boolean} data the loading status.
     */
    SET_LOADING_MEASUREMENTS(state, data) {
      state.loadingMeasures = data
    },

    /**
     * Sets the `measurements` property.
     *
     * @param {Object} state the measure state.
     * @param {MeasurementEntity[]} data an array of measurements.
     */
    SET_MEASUREMENTS(state, data) {
      state.measurements = data
    },

    /**
     * Sets the `measurementPaths` property.
     *
     * @param {Object} state the measure state.
     * @param {MeasurementEntity[]} data an array of measurements.
     */
    SET_MEASUREMENTS_PATHS(state, data) {
      state.measurementPaths = data
    },

    /**
     * Sets the `currentMeasurement` property.
     *
     * @param {Object} state the measurement state.
     * @param {Measurement} data the current measurement.
     */
    SET_CURRENT_MEASUREMENT(state, data) {
      state.currentMeasurement = data
    },
  },
  actions: {
    /**
     * Update a measurement data.
     *
     * @param {Store} store the vuex store.
     * @param {Array[]} payload array with the document paths.
     */
    async updateMeasurement({ commit, dispatch }, payload) {
      commit('SET_LOADING', true)

      try {
        const path = `${payload.parent}/measurements/${payload.id}`

        await measurementController.updateConsumer([payload.parent, "/measurements/", payload.id], payload.data)
        dispatch('fetchOneMeasurement', path)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads measurements according to the given filters.
     *
     * @param {Store} store the vuex store.
     * @param {QueryConstraint[]} payload the fetch filters.
     */
    async fetchMeasurements({ commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const measurements = await measurementController.getAll(payload)
        commit('SET_MEASUREMENTS', measurements)

        const path = payload ? `${payload}/measurements` : 'measurements'

        measurementController.listenMultiple(path, (meas) => {
          commit('SET_MEASUREMENTS', meas)
        })
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Loads all measurements from the application, including children ones.
     *
     * @param {Store} store the vuex store.
     */
    async fetchAllMeasurements({ commit }) {
      commit('SET_LOADING', true)

      try {
        const measurements = await measurementController.getAll(null)
        const opticsMeasurements = await getAllMeasurements('optics')
        const labsMeasurements = await getAllMeasurements('laboratories')
        const spMeasurements = await getAllMeasurements('software-partners')

        measurements.push(
          ...opticsMeasurements.measurements,
          ...labsMeasurements.measurements,
          ...spMeasurements.measurements,
        )

        const measurementPaths = {
          ...opticsMeasurements.paths,
          ...labsMeasurements.paths,
          ...spMeasurements.paths,
        }

        commit('SET_MEASUREMENTS', measurements)
        commit('SET_MEASUREMENTS_PATHS', measurementPaths)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Get an measurement from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the measurement id.
     * @returns {Promise<MeasurementEntity>} an measurement entity.
     */
    async fetchOneMeasurement({ state, commit }, payload) {
      commit('SET_LOADING', true)

      try {
        const measurement =
          state.measurements.find((m) => m.id === payload) ??
          (await measurementController.getOne(payload))

        if (!measurement) {
          throw new Error('measurement-not-found')
        }
        let count = 0
        new MeasurementController().listenOne(payload, (m) => {
          if (count >= 1) commit('SET_CURRENT_MEASUREMENT', m)
          count++
        })

        return measurement
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },

    /**
     * Gets an image URL according to the given path.
     *
     * @param {string} payload the image path.
     * @returns the image URL.
     */
    async getImageUrl(_, payload) {
      try {
        if (!payload) {
          return ''
        }

        const url = await measurementController.getImageUrl(payload)

        if (!url) {
          throw new Error('image-not-found')
        }

        return url
      } catch (e) {
        throw e
      }
    },
    /**
     * Deletes a software partner from the database.
     *
     * @param {Store} store the vuex store.
     * @param {string} payload the software partner id.
     */
    async deleteMeasurement({ commit, state }, payload) {
      commit('SET_LOADING', true)

      try {
        let id = ''

        if (state.measurementPaths && state.measurementPaths[payload]) {
          id = state.measurementPaths[payload]
        } else if (!payload.includes('measurements')) {
          id = `measurements/${payload}`
        } else {
          id = payload
        }

        await measurementController.delete(id)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
