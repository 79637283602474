<template>
  <b-card v-if="lineSeries[0]" class="card-tiny-line-stats" body-class="pb-50">
    <h6>{{ $t('home.statistics.measurementsLastSevenDays') }}</h6>
    <h2 class="font-weight-bolder mb-1">
      {{ getQtyMeasurementsLast7Days() }}
    </h2>
    <!-- chart -->
    <vue-apex-charts
      height="70"
      :options="statisticsProfit.chartOptions"
      :series="lineSeries"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { onUnmounted } from 'vue-demi'
import store from '@/store'

import statsStoreModule from '../stats/statsStoreModule'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 1,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  computed: {
    lineSeries() {
      return store.state.stats.lineSeries || []
    },
  },
  methods: {
    getQtyMeasurementsLast7Days() {
      let cont = 0
      this.lineSeries[0].data.forEach((element) => {
        if (element > 0) {
          cont += element
        }
      })
      return cont
    },
  },
  setup() {
    const STATS_APP_STORE_MODULE_NAME = 'stats'

    if (!store.hasModule(STATS_APP_STORE_MODULE_NAME)) {
      store.registerModule(STATS_APP_STORE_MODULE_NAME, statsStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(STATS_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(STATS_APP_STORE_MODULE_NAME),
    )

    store.dispatch('stats/measurementsOfCurrentMonth', {
      name: store.state.auth.user.name,
      parent:
        store.state.auth.user.adminDocRef || store.state.auth.user.sellerDocRef,
    })
  },
}
</script>
