import { computed, ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import store from '@/store'
import SellerEntity from '@/entities/seller.entity'
import UserController from '@/controllers/user.controller'

export default function useSellersList() {
  /**
   * Property that defines a reference to the sellers table.
   */
  const refSellersListTable = ref(null)

  /**
   * Property that defines the searching string from the sellers list.
   *
   * @default ''
   */
  const search = ref('')

  /**
   * Property that defines the column that will be used to sort the
   * table.
   *
   * @default 'id'
   */
  const sortBy = ref('createdAt')

  /**
   * Property that defines whether the sort will be ascending or
   * descending.
   *
   * @default true
   */
  const isSortDirDesc = ref(true)

  /**
   * Property that defines the table current page.
   *
   * @default 1
   */
  const currentPage = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = 10

  /**
   * Property that stores the fetched sellers.
   *
   * @type {import('@vue/composition-api').ComputedRef<SellerEntity[]>}
   */
  const sellers = computed({
    get: () => store.state.seller.sellers,
    set: (value) => store.commit('seller/SET_SELLERS', value),
  })

  /**
   * Property that defines the sellers list headers.
   */
  const tableColumns = computed(() => {
    return [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
      },
      {
        key: 'nameRegistered',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.name'),
        sortable: true,
      },
      // { key: 'nameRegistered', label: 'Registered name', sortable: true },
      {
        key: 'userEmail',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.email'),
        sortable: true,
      },
      {
        key: 'qtyMeasurements',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.measurements'),
        sortable: true,
      },
      {
        key: 'status',
        label: i18n.t('admin.labs.listLabs.sellerHeaders.status'),
        sortable: true,
      },
      {
        key: 'actions',
        label: i18n.t('admin.labs.listLabs.userHeaders.actions'),
        sortable: false,
      },
    ]
  })

  /**
   * Deletes a seller from the database.
   *
   * @param {string} id the seller id.
   */
  const deleteSeller = async (company, email, id, parent) => {
    try {
      const path = `${parent}/sellers/${id}`
      await store.dispatch('seller/deleteSeller', {
        path,
        userId: store.state.auth.userId,
      })
      store.dispatch('seller/fetchSellers', parent)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Bloqueado',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountBlockedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Restores a seller from the database.
   *
   * @param {string} id the seller id.
   */
  const restoreSeller = async (company, email, id, parent) => {
    try {
      let path = parent ? `${parent}/` : ''

      path += `sellers/${id}`
      await store.dispatch('seller/restoreSeller', path)
      store.dispatch('seller/fetchSellers', parent)

      const emailData = {
        company: company,
        toEmail: email,
        subject: 'Acesso Restaurado!',
        name: 'Lensxpert',
        fromEmail: '',
      }
      await new UserController().sendAccountReactivatedEmail(emailData)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    currentPage,
    isSortDirDesc,
    refSellersListTable,
    search,
    sortBy,
    tableColumns,
    sellers,
    deleteSeller,
    restoreSeller,
    perPage,
    perPageOptions,
  }
}
