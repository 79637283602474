<template>
  <div>
    <b-row class="match-height">
      <b-col
        :lg="!canSeeStatistics ? 6 : isSoftwareAdmin || isSuperAdmin ? 4 : 3"
        md="6"
      >
        <card-welcome />
      </b-col>
      <b-col
        :lg="!isSoftwareAdmin && !isSuperAdmin ? 6 : 8"
        md="6"
        v-if="canSeeStatistics"
      >
        <card-statistics />
      </b-col>
      <b-col :lg="!canSeeStatistics ? 6 : 3" md="6">
        <card-bar-chart v-if="!isSoftwareAdmin && !isSuperAdmin" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <optics-list v-if="isLabAdmin" />
        <measures-list v-if="isOpticAdmin || isSeller" :preDefinedPerPage="5" />

        <div v-if="isSoftwareAdmin">
          <h2 class="ml-1">{{ $t('navbar.laboratories') }}</h2>
          <labs-list />
          <h2 class="mt-2 ml-1">{{ $t('navbar.optics') }}</h2>
          <optics-list />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from 'bootstrap-vue'
import CardWelcome from './admin/dashboard/CardWelcome.vue'
import CardStatistics from './admin/dashboard/CardStatistics.vue'
import CardBarChart from './admin/dashboard/CardBarChart.vue'

import CardMeasurementsMonth from './admin/dashboard/CardMeasurementsMonth.vue'
import MeasuresList from '@/views/admin/measurements/MeasurementsList.vue'
import OpticsList from '@/views/sections/optics/OpticsList.vue'
import LabsList from '@/views/sections/labs/LabsList.vue'
import RoleEnum from '@/utils/roles'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    CardWelcome,
    CardStatistics,
    MeasuresList,
    CardMeasurementsMonth,
    CardBarChart,
    OpticsList,
    LabsList,
  },
  computed: {
    isLabAdmin() {
      return this.user.role === RoleEnum.LABORATORY_ADMIN ? true : false
    },
    isOpticAdmin() {
      return this.user.role === RoleEnum.OPTIC_ADMIN ? true : false
    },
    isSeller() {
      return this.user.role === RoleEnum.SELLER ? true : false
    },
    isSoftwareAdmin() {
      return this.user.role === RoleEnum.SOFTWARE_ADMIN ? true : false
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN ? true : false
    },
    user() {
      return this.$store.state.auth.user
    },
    canSeeStatistics() {
      if (this.user.role == RoleEnum.SELLER) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped>
body {
  font-family: 'Montserrat', Helvetica, Arial, serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
}
</style>