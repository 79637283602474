<template>
  <b-card
    v-if="userAdminDocData || adminMetrics"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ $t('home.statistics.statisticsTitle') }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          :xl="
            statisticsItems.length > 3
              ? statisticsItems.length > 4
                ? 4
                : 3
              : 6
          "
          :lg="statisticsItems.length > 3 ? 6 : 6"
          :md="statisticsItems.length > 3 ? 6 : 6"
          :sm="statisticsItems.length > 3 ? 6 : 6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <a :href="item.route">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </a>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import RoleEnum from '@/utils/roles'
import Controller from '@/controllers/base.controller'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      adminMetrics: null,
    }
  },
  computed: {
    statisticsItems() {
      let baseItems = []

      if (this.isSuperAdmin) {
        baseItems = [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: this.adminMetrics.qtyMeasurements,
            subtitle: this.$i18n.t('home.statistics.measures'),
            route: '/admin/measurements',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },

          {
            icon: 'UserIcon',
            color: 'light-info',
            title: this.adminMetrics.qtySellers,
            subtitle: this.$i18n.t('home.statistics.sellers'),
            route: '/admin/sellers',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
          {
            icon: 'EyeIcon',
            color: 'light-warning',
            title: this.adminMetrics.qtyOptics,
            subtitle: this.$i18n.t('home.statistics.optics'),
            route: '/admin/optics',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
          {
            icon: 'ThermometerIcon',
            color: 'light-success',
            title: this.adminMetrics.qtyLabs,
            subtitle: this.$i18n.t('home.statistics.labs'),
            route: '/admin/laboratories',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
          {
            icon: 'BriefcaseIcon',
            color: 'light-danger',
            title: this.adminMetrics.qtySoftwarePartners,
            subtitle: this.$i18n.t('home.statistics.softwarePartners'),
            route: '/admin/software-partners',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
        ]
      } else {
        baseItems = [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: this.userAdminDocData.qtyMeasurements,
            subtitle: this.$i18n.t('home.statistics.measures'),
            route: '/admin/measurements',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: this.userAdminDocData.qtySellers,
            subtitle: this.$i18n.t('home.statistics.sellers'),
            route: '/admin/sellers',
            customClass: 'mb-2 mb-xl-0 pb-1',
          },
        ]

        if (
          this.user.role === RoleEnum.SOFTWARE_ADMIN ||
          this.user.role === RoleEnum.SUPER_ADMIN
        ) {
          baseItems.push(
            {
              icon: 'EyeIcon',
              color: 'light-warning',
              title: this.userAdminDocData.qtyOptics,
              subtitle: this.$i18n.t('home.statistics.optics'),
              route: '/admin/optics',
              customClass: 'mb-2 mb-xl-0 pb-1',
            },
            {
              icon: 'ThermometerIcon',
              color: 'light-success',
              title: this.userAdminDocData.qtyLabs,
              subtitle: this.$i18n.t('home.statistics.labs'),
              route: '/admin/laboratories',
              customClass: 'mb-2 mb-xl-0 pb-1',
            },
          )
        }
      }

      return baseItems
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN ? true : false
    },
    user() {
      return this.$store.state.auth.user
    },
    userAdminDocData() {
      return this.$store.state.auth.userAdminDoc
    },
  },
  async created() {
    new Controller().getById(['admin-metrics', 'metrics']).then((res) => {
      this.adminMetrics = res.data()
    })
  },
}
</script>
